import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global from '../services/global.service';

import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        var currentUser: any = localStorage.getItem('currentUserValkirya');
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(currentUser));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    isAuthenticated(val: any) {
      if (val !== '' && val != null) {
        if (val.split('.').length === 3) {
          try {
            const base64Url = val.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const exp = JSON.parse(window.atob(base64)).exp;
            // JWT with an optonal expiration claims
            if (exp) {
              const isExpired = Math.round(new Date().getTime() / 1000) >= exp;
              if (isExpired) {
                // FAIL: Expired token
                return false;
              } else {
                // PASS: Non-expired token
                return true;
              }
            }
          } catch (e) {
            // PASS: Non-JWT token that looks like JWT
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
      return this.http.post<any>(global.API_URL + '/api/auth/login', { email, password })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUserValkirya', JSON.stringify(user.user));
                this.currentUserSubject.next(user.user);
            }

            return user;
        }));
  }
  refresh() {
    return this.http.get<any>(global.API_URL + '/api/auth/refresh').pipe(map(user => {
      console.log('return');
        if (user && user.access_token) {
            localStorage.setItem('currentUserValkirya', JSON.stringify(user.user));
            this.currentUserSubject.next(user.user);
        }
        return user;
    }));
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUserValkirya');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
}
}
