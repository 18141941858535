<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/fondo_world_contratos.png) no-repeat center center; background-size: cover;">
    <div class="auth-box m-3 m-md-0">
        <div id="loginform">
            <div class="logo">
                <span class="db"></span>
            </div>
            <!-- Form -->
            <div class="row">
                <div class="col-12">
                      <form class="form-horizontal m-t-20" [formGroup]="formLogin!">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                            </div>
                            <input class="form-control" type="text" id="username" required="" placeholder="{{ 'Email' }}" formControlName="username" name="username" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                            </div>
                            <input class="form-control" type="password" required="" id="password" placeholder="{{ 'Contraseña' }}" formControlName="password" name="password" aria-describedby="basic-addon1">
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12">
                                <button class="btn btn-block btn-lg btn-info btn-rounded" (click)="login(formLogin.value)">{{ 'Accede con mi cuenta' }}</button>
                            </div>
                        </div>
                       <!--
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                                <div class="social">
                                    <a href="javascript:void(0)" class="btn btn-facebook mr-2" data-toggle="tooltip" title="" data-original-title="Login with Facebook"> <i aria-hidden="true" class="fab fa-facebook"></i> </a>
                                    <a href="javascript:void(0)" class="btn btn-googleplus" data-toggle="tooltip" title="" data-original-title="Login with Google"> <i aria-hidden="true" class="fab fa-google-plus"></i> </a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group m-b-0 m-t-10">
                            <div class="col-sm-12 text-center">
                                ¿No tienes cuenta? <a href="authentication/signup" class="text-info m-l-5"><b>Sign Up</b></a>
                            </div>
                        </div>-->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>