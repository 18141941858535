import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import * as global from '../services/global.service';
import { ApicallsService } from '../services/apicalls.service';
import { SignaturePad } from 'angular2-signaturepad';

import {Title} from '@angular/platform-browser';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-autosign',
  templateUrl: './autosign.component.html',
  styleUrls: ['./autosign.component.scss']
})
export class AutosignComponent implements OnInit {

  formLogin!: FormGroup;
  formSign!: FormGroup;
  formPin!: FormGroup;
  loading = false;
  showpin = false;
  submitted = false;
  returnUrl: any;
  allowed = true;
  contrato: any;
  logo = '';
  empresa = '';
  signature: any;
  logged = false;
  download = false;
  contracts: any;
  currentContract: any;
  showContract = false;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 400,
    'canvasHeight': 200
  };

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private title: Title,
              private api: ApicallsService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.title.setTitle('Firma de contratos Yoigo');
    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email ]],
      dni: ['', Validators.required]
    });
    this.formSign = this.formBuilder.group({
      firma: ['', Validators.required],
    });
    this.formPin = this.formBuilder.group({
      pin: ['', Validators.required],
      email: ['', [Validators.required]],
    });
  }

  login(formValue: any) {
    this.api.autosign_login(formValue).subscribe((data: any) => {
      if (data.error) {
        this.toastr.error('No tienes permiso para acceder a la aplicación', 'Firma contratos');

      } else {
        this.toastr.info('Para entrar introduce el PIN enviado a tu email', 'Firma contratos');

        this.formPin.patchValue({email: formValue.email});
        this.showpin = true;
      }
    }, error => {
      this.toastr.error('Se produjo un error al hacer login', 'Firma contratos');
    });
  }

  checkPin(formValue: any) {
    this.api.checkPin(formValue).subscribe((data: any) => {
      if (data.error === 0) {
        this.logged = true;
        this.getContracts();
      } else {
        this.toastr.error(data.errormsg, 'Firma contratos');

        if (data.type === 0) {
          this.formLogin.reset();
          this.showpin = false;
        }
      }
    }, error => {
      this.toastr.error('Se produjo un error al comprobar el PIN', 'Firma contratos');
    });
  }

  getContracts() {
    const dni = this.formLogin.value.dni;

    this.api.getAllContractsToCompany(dni).subscribe((data: any) => {
      this.contracts = data.response;
      for (let i = 0; i < this.contracts.length; i++) {
        this.contracts[i].fecha =  moment(this.contracts[i].fecha_alta).format('DD/MM/YYYY');
        this.contracts[i].firmado = 'No';
        if (this.contracts[i].firma !== '') {
          this.contracts[i].firmado = 'Si';
        }
      }
    });
  }

  public drawBegin(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png', 0.5);
    this.formSign.patchValue({firma: this.signature});
  }

  deleteSignature() {
    this.signaturePad.clear();
    this.signature = null;
  }

  sign(formvalue: any) {
    this.api.sign_contract(this.currentContract.id, formvalue).subscribe( data => {
      this.
       loading = false;
       this.toastr.success('Tu contrato ha sido firmado. Puedes descargarlos desde el boton descargar contratos.', 'Firma contratos');
      this.download = true;
    }, error => {
      this.
      loading = false;
      this.toastr.error('Tu contrato no se ha podido firmar. Por favor intentalo de nuevo y si persiste el problema ponte en contacto con tu comercial', 'Firma contratos');

    });
  }

  downloadContracts(id: any) {
    this.loading = true;
    this.api.generatePdfContracts(id).subscribe( (data: any) => {
      const url = data.response;
      this.loading = false;
      window.open(global.API_URL + url, '_blank');
    }, err => {
        this.toastr.error('Tu contrato no se ha podido descargar. Por favor intentalo de nuevo y si persiste el problema ponte en contacto con tu comercial', 'Firma contratos');

    });
  }

  view(id: any) {
    for (let i = 0; i < this.contracts.length; i++) {
      if (this.contracts[i].id === id) {
        this.currentContract = this.contracts[i];
        this.logo = global.API_URL + this.currentContract.empresa.logo;
        this.showContract = true;
      }
    }
  }

  descargar(id: any) {

  }

  volver() {
    this.getContracts();
    this.showContract = false;
    this.currentContract = null;
  }

}
