import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  year: any;
  formLogin!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;
  
  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private api: ApicallsService,
              private router: Router,
              private authenticationService: AuthenticationService) {

              if (this.authenticationService.currentUserValue) {
                  this.router.navigate(['/dashboard']);
              }
  }
  ngOnInit() {
    this.year = new Date().getFullYear();
    this.formLogin = this.formBuilder.group({
        username: ['', [ Validators.required, Validators.email]],
        password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  login(formValue: any) {
    this.authenticationService.login( formValue.username , formValue.password )
      .pipe(first())
      .subscribe( (data: any) => {
        this.router.navigate(['/dashboard']);
      },
      error => {
        this.toastr.error('No tienes permiso para entrar a la aplicación');
      });
  }

}
