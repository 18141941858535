import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import * as global from '../services/global.service';
import { ApicallsService } from '../services/apicalls.service';
import { SignaturePad } from 'angular2-signaturepad';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {

  formLogin!: FormGroup;
  formSign!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: any;
  allowed = true;
  contrato: any;
  logo = '';
  empresa = '';
  signature: any;
  logged = false;
  download = false;

  @ViewChild(SignaturePad) signaturePad: SignaturePad | undefined;

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 400,
    'canvasHeight': 200
  };

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private api: ApicallsService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    
    this.formLogin = this.formBuilder.group({
      pin: ['', Validators.required],
      dni: ['', Validators.required]
    });
    this.formSign = this.formBuilder.group({
      firma: ['', Validators.required],
    });
    
    this.route.queryParams.subscribe(params => {
        this.loading = true;
        const key = params.key;
        const id = params.u;
        if (typeof key === 'undefined' || typeof id === 'undefined') {
          this.toastr.error('La url de entrada no esta permitida. Si necesita firmar un contrato y se le ha enviado el enlace y es incorrecto pongase en contacto con su comercial.', 'Firma contratos');
          
        } else {
          this.api.sign_process(id, key).subscribe((data: any) => {
            
            this.loading = false;
            if (data.error) {
              
              this.toastr.error('Ocurrió el siguiente error: ' + data.error, 'Firma contratos');
              this.allowed = false;
            } else {
              
              this.contrato = data.contrato;
              this.logo = global.API_URL + data.empresa.logo;
              this.empresa = data.empresa;
              if (this.contrato.tipo_contrato === 1 && this.contrato.tipo_empresa === 1) {
                this.contrato.name = this.contrato.cliente.razon;
              } else {
                this.contrato.name = this.contrato.cliente.nombre + ' ' + this.contrato.cliente.apellido1 +
                  ' ' + this.contrato.cliente.apellido2;
              }
              this.allowed = true;
            }
          });
        }
    });
    
  }

  login(formValue: any) {
    if (this.contrato.pin === formValue.pin && this.contrato.cliente.num_documento === formValue.dni) {
      this.logged = true;
    } else {
        this.toastr.error('No tienes permiso para entrar a la firma', 'Firma contratos');
    }
  }

  public drawBegin(): void {
    console.log('Begin Drawing');
  }

  public drawComplete(): void {
    this.signature = this.signaturePad!.toDataURL('image/png', 0.5);
    this.formSign.patchValue({firma: this.signature});
  }

  deleteSignature() {
    this.signaturePad!.clear();
    this.signature = null;
  }

  sign(formvalue: any) {
    this.api.sign_contract(this.contrato.id, formvalue).subscribe( data => {
      this.loading = false;
      this.toastr.success('Tu contrato ha sido firmado, puedes descargarlo desde el botón descargar contratos', 'Firma contratos');
      this.download = true;
    }, error => {
      this.loading = false;
      this.toastr.error('Tu contrato no se ha podido firmar. Por favor intentalo de nuevo y si persiste el problema ponte en contacto con tu comercial', 'Firma contratos');

    });
  }

  downloadContracts() {
    this.loading = true;
    this.api.generatePdfContracts(this.contrato.id).subscribe( (data: any) => {
      const url = data.response;
      this.loading = false;
      window.open(global.API_URL + url, '_blank');
    }, err => {
   
        this.toastr.error('Tu contrato no se ha podido descargar. Por favor intentalo de nuevo y si persiste el problema ponte en contacto con tu comercial', 'Firma contratos');

    });
  }

}
