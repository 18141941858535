<div *ngIf="!logged" class="account-pages">
  <!-- Begin page -->
  <div *ngIf="allowed" class="wrapper-page account-page-full">
    <div class="card">
      <div class="card-block">
        <div class="account-box">
          <div class="card-box p-5">
            <h2 class="text-uppercase text-center pb-4">
              Contratos Yoigo
            </h2>
            <form *ngIf="!showpin" class="" [formGroup]="formLogin">
              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <label for="dni">CIF / NIF / NIE</label>
                  <input class="form-control" type="text" id="usuario" required="" placeholder="Introduce tu numero de documento"
                  formControlName="dni" name="dni">
                </div>
              </div>
              <div class="form-group row m-b-20">
                <div class="col-12">
                  <!-- <a href="page-recoverpw.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
                  <label for="email">Email</label>
                  <input class="form-control" type="text" required="" id="email" placeholder="Introduce tu email"
                   formControlName="email" name="email">
                </div>
              </div>
              <div class="form-group row text-center m-t-50">
                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block" (click)="login(formLogin.value)">Entrar</button>
                </div>
              </div>
            </form>
            <form *ngIf="showpin" class="" [formGroup]="formPin">
              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <label for="pin">PIN</label>
                  <input class="form-control" type="text" id="usuario" required="" placeholder="Introduce número PIN"
                  formControlName="pin" name="pin">
                </div>
              </div>
              <div class="form-group row text-center m-t-50">
                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block" (click)="checkPin(formPin.value)">Entrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="popup">
  <div class="content">
      <div class="spinner"><span class="fas fa-spinner fa-pulse"></span></div>
     <div class="text">Cargando ...</div> 
  </div>
</div>
<div *ngIf="logged" class="contract">
  <div class="container">
    <div class="wrapper">
      <h1>Contratos YOIGO</h1>
      <h4 *ngIf="showContract === false">Este es el listado de contratos de Yoigo que tienes actualmente.</h4>
      <div *ngIf="showContract === false" class="card-block">
        <p class="card-title">Contratos registrados</p>
        <div id="no-more-tables" class="card-body table">
          <table class="col-sm-12 table-bordered table-striped table-condensed cf">
             <thead class="cf">
                <tr>
                   <th>Empresa</th>
                   <th>Fecha de alta</th>
                   <th>Firmado</th>
                   <th>Acciones</th>
                </tr>
             </thead>
             <tbody>
                <tr *ngFor="let item of contracts">
                   <td data-title="Empresa"><b>{{item.empresa.nombre}}</b></td>
                   <td data-title="Fecha de alta">{{item.fecha}}</td>
                   <td data-title="Firmado">{{item.firmado}}</td>
                   <td data-title="Acciones">
                    <div *ngIf="item.firmado === 'Si'" class="action-link" (click)="view(item.id)">Descargar contrato</div>
                    <div *ngIf="item.firmado === 'No'" class="action-link" (click)="view(item.id)">Firmar contrato</div>
                   </td>
                </tr>
             </tbody>
          </table>
       </div>
      </div>
      <ng-container *ngIf="showContract === true">
        <img class="logo" [src]="logo" alt="{{currentContract?.empresa.nombre}}" >
        <div class="wrapper">
          <h1 *ngIf="currentContract?.firmado === 'No'">Firma de contratos YOIGO</h1>
          <h4 *ngIf="currentContract?.firmado === 'No'">Repasa los datos de tu contrato antes de firmar. Al final del formulario tendrás el botón y espacio para firmar.</h4>
          <h4 *ngIf="currentContract?.firmado === 'Si'">Estos son los datos de tu contrato. Al final del mismo tendrás el botón para iniciar su descarga.</h4>
          <div class="card-block">
            <p class="card-title">Datos del contratante</p>
            <div class="card-body">
              <p class="card-line"><span>Nombre o razón social:</span>{{currentContract?.name}}</p>
              <p *ngIf="currentContract?.tipo_contrato === 0 || currentContract?.tipo_empresa === 0" class="card-line"><span>CIF / NIF / NIE:</span>{{currentContract?.cliente.num_documento}}</p>
              <p *ngIf="currentContract?.tipo_contrato === 1 && currentContract?.tipo_empresa === 1" class="card-line">
                <span>CIF / NIF / NIE:</span>{{currentContract?.cliente.num_documento}}
                <span class="continue">Fecha constitución:</span>{{currentContract?.cliente.constitucion | date: 'dd-MM-yyyy'}}
              </p>
              <p class="card-line"><span>Dirección:</span>{{currentContract?.cliente.domicilio}}</p>
              <p class="card-line">
                <span>CP:</span>{{currentContract?.cliente.cp}}
                <span class="continue">Localidad:</span>{{currentContract?.cliente.localidad.nombre}}
                <span class="continue">Provincia:</span>{{currentContract?.cliente.provincia.nombre}}
              </p>
              <p class="card-line">
                <span>Teléfono:</span>{{currentContract?.cliente.telefono}}
                <span class="continue">Email:</span>{{currentContract?.cliente.email}}
              </p>
              <p class="card-line">
                <span>CCC:</span>{{currentContract?.cliente.ccc}}
              </p>
            </div>
          </div>
          <div class="card-block">
            <p class="card-title">Datos de la persona autorizada</p>
            <div class="card-body">
              <p class="card-line"><span>Nombre:</span>{{currentContract?.autorizado.nombre}} {{currentContract?.autorizado.apellido1}} {{currentContract?.autorizado.apellido2}}</p>
              <p  class="card-line"><span>NIF / NIE:</span>{{currentContract?.autorizado.num_documento}}</p>
              <p  class="card-line">
                <span>Fecha nacimiento:</span>{{currentContract?.autorizado.fecha_nac | date:'dd-MM-yyyy'}}
                <span class="continue">Nacionalidad:</span>{{currentContract?.autorizado.nacionalidad}}
              </p>
              <p class="card-line">
                <span>Teléfono:</span>{{currentContract?.autorizado.telefono}}
                <span class="continue">Email:</span>{{currentContract?.autorizado.email}}
              </p>
            </div>
          </div>
          <div class="card-block">
            <p class="card-title">Datos de la persona de contacto</p>
            <div class="card-body">
              <p class="card-line"><span>Nombre:</span>{{currentContract?.contacto.nombre}} {{currentContract?.contacto.apellido1}} {{currentContract?.contacto.apellido2}}</p>
              <p  class="card-line"><span>NIF / NIE:</span>{{currentContract?.contacto.num_documento}}</p>
              <p  class="card-line">
                <span>Fecha nacimiento:</span>{{currentContract?.contacto.fecha_nac | date:'dd-MM-yyyy'}}
                <span class="continue">Nacionalidad:</span>{{currentContract?.contacto.nacionalidad}}
              </p>
              <p class="card-line">
                <span>Teléfono:</span>{{currentContract?.contacto.telefono}}
                <span class="continue">Email:</span>{{currentContract?.contacto.email}}
              </p>
            </div>
          </div>
          <div *ngIf="currentContract?.fijo" class="card-block">
            <p class="card-title">Datos de la linea fija</p>
            <div class="card-body">
              <p *ngIf="currentContract?.fijo.portafijo === 0" class="card-line"><span>Tipo:</span> Alta nueva linea</p>
              <p *ngIf="currentContract?.fijo.portafijo === 1" class="card-line"><span>Tipo:</span> Portabilidad</p>
              <p *ngIf="currentContract?.fijo.portafijo === 1" class="card-line">
                <span>Número:</span>{{currentContract?.fijo.num_porta}}
                <span class="continue">Operador donante:</span>{{currentContract?.fijo.operador.nombre}}
              </p>
              <p class="card-line"><span>Tarifa:</span>{{currentContract?.fijo.tarifa.nombre}}</p>
              <p *ngIf="currentContract?.fijo.tv === 0 && currentContract?.fijo.tvgratis === 0" class="card-line"><span>Agile Tv:</span>No</p>
              <p *ngIf="currentContract?.fijo.tv === 1" class="card-line"><span>Agile Tv:</span>Si</p>
              <p *ngIf="currentContract?.fijo.tvgratis === 1" class="card-line"><span>Agile Tv:</span>Si (Promoción gratis)</p>
              <p class="card-line"><span>Dirección instalación:</span>{{currentContract?.fijo.domicilio}}</p>
              <p class="card-line">
                <span>CP:</span>{{currentContract?.fijo.cp}}
                <span class="continue">Localidad:</span>{{currentContract?.fijo.localidad.nombre}}
                <span class="continue">Provincia:</span>{{currentContract?.fijo.provincia.nombre}}
              </p>
              
            </div>
          </div>
          <div *ngIf="currentContract?.lineas.length > 0" class="card-block">
            <p class="card-title">Datos de las lineas móviles</p>
            <div class="card-body">
              <ng-container *ngFor="let item of currentContract?.lineas; let i = index">
                <p class="card-line"><span>Línea {{i + 1}}</span></p>
                <p class="card-line">
                  <span>Movimiento:</span>{{item.movimiento}}
                  <span class="continue">Tarifa:</span>{{item.tarifa.nombre}}
                </p>
                <p *ngIf="item.movimiento === 'Portabilidad'" class="card-line">
                  <span>Numero:</span>{{item.numero}}
                  <span class="continue">Operador donante:</span>{{item.operador.nombre}}
                </p>
                <p *ngIf="item.movimiento !== 'Portabilidad'" class="card-line">
                  <span>Numero:</span>{{item.tipo_movimiento}}
                </p>
                <p class="card-line"><span>Sim:</span>{{item.tipo_movimiento}}</p>
                <hr>
              </ng-container>   
            </div>
          </div>
          <div *ngIf="currentContract?.terminales.length > 0" class="card-block">
            <p class="card-title">Datos de los terminales</p>
            <div class="card-body">
              <ng-container *ngFor="let item of currentContract?.terminales; let i = index">
                <p class="card-line"><span>Terminal {{i + 1}}</span></p>
                <p class="card-line">
                  <span>Terminal:</span>{{item.terminal.nombre}}
                </p>
                <p class="card-line">
                  <span>Aplazado:</span>{{item.aplazado}}
                </p>
                <p class="card-line">
                  <span>Pago inicial:</span>{{item.inicial}} €
                  <span class="continue">Pago mes:</span>{{item.pagomes}} €
                  <span class="continue">Pago final:</span>{{item.final}} €
                </p>
                <hr>
              </ng-container>   
            </div>
          </div>
        </div>
        <form *ngIf="currentContract?.firmado === 'No'"  class="" [formGroup]="formSign">
          <div class="form-row">
            <div id="signature-pad" style="width: 100%;margin: 50px auto;display: flex;justify-content: center;">
              <signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"></signature-pad>
            </div>
          </div>
          <div class="buttons">
            <a *ngIf="!download" class="btn btn-primary" (click)="deleteSignature()">Borrar firma</a>
            <button *ngIf="!download" class="btn btn-primary btn-block" (click)="sign(formSign.value)" [disabled]="formSign.invalid">Firmar</button>
            <a *ngIf="download" class="btn btn-primary download" (click)="downloadContracts(currentContract?.id)">Descargar contratos</a>
          </div>
        </form>
        <div *ngIf="currentContract?.firmado === 'Si'" class="btn btn-download" (click)="downloadContracts(currentContract?.id)">
          Iniciar descarga
        </div>
        <div class="btn btn-download" (click)="volver()">
          Volver
        </div>
      </ng-container>
  </div>
</div>