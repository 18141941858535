import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as global from './global.service';
import { InjectorInstance } from '../app.module';
import { AuthenticationService } from './authentication.service';

@Injectable({providedIn: 'root'})
export class ApicallsService {

  constructor(public http: HttpClient, private auth: AuthenticationService) {

  }
  //ADMINISTRADORES
  getAdministradores() {
    return this.http.get(global.API_URL + '/api/users/admins');
  }
  getAllUsers() {
    return this.http.get(global.API_URL + '/api/users');
  }
  getAllUsersWithRole(empresa:any) {
    return this.http.get(global.API_URL + '/api/users/with_role/'+empresa);
  }
  newUser(user: any) {
    return this.http.post( global.API_URL + '/api/users/register', user);
  }

  getAllRoles() {
    return this.http.get(global.API_URL + '/api/roles');
  }
  getRol(id: any) {
    return this.http.get(global.API_URL + '/api/roles/view/' + id);
  }

  assignRolesPermisos(permisos: any, id: string) {
    return this.http.post( global.API_URL + '/api/roles/assign_permisos/' + id, permisos);
  }
  getPermisosByRole(permisos: any, id: string) {
    return this.http.post( global.API_URL + '/api/roles/get_permisos/' + id, permisos);
  }

  editUser(user: any, id: any) {
    user.audit_user = this.auth.currentUserValue.id;
    user.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/users/update/' + id, user);
  }

  existsEmail(email: any){
    return this.http.get( global.API_URL + '/api/users/email/' + email);
  }

  changePassword(user: any, id: any) {
    return this.http.post( global.API_URL + '/api/users/password/' + id, user);
  }

  renewPassword(id: any) {
    return this.http.post( global.API_URL + '/api/users/renew_password/' + id, {});
  }

  deleteUser(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/users/delete/' + id, data);
  }

  assignUserRoles(roles: any, id: string) {
    return this.http.post( global.API_URL + '/api/users/assign_roles/' + id, roles);
  }
  getRolesByUser(roles: any, id: string) {
    return this.http.post( global.API_URL + '/api/users/get_roles/' + id, roles);
  }
  deactiveUser(id: any) {
    return this.http.post(global.API_URL + '/api/users/deactivate/' + id, null);
  }

  getUser(id: any) {
    return this.http.get(global.API_URL + '/api/users/view/' + id);
  }

  searchUser(search: any) {
    return this.http.post(global.API_URL + '/api/users/search', search);
  }
  //OPERADORES

  getAllOperators() {
    return this.http.get(global.API_URL + '/api/operator');
  }

  newOperator(operator: any) {
    operator.audit_user = this.auth.currentUserValue.id;
    operator.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/operator/create', operator);
  }

  getOperator(id: any) {
    return this.http.get(global.API_URL + '/api/operator/view/' + id);
  }

  editOperator(operator: any, id: any) {
    operator.audit_user = this.auth.currentUserValue.id;
    operator.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/operator/update/' + id, operator);
  }

  deleteOperator(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/operator/delete/' + id, data);
  }

  // EMPRESAS

  getAllEmpresas() {
    return this.http.get(global.API_URL + '/api/empresas');
  }

  newEmpresa(company: any) {
    company.audit_user = this.auth.currentUserValue.id;
    company.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/empresas/create', company);
  }

  getEmpresa(id: any) {
    return this.http.get(global.API_URL + '/api/empresas/view/' + id);
  }
  getDashboard(id: any) {
    return this.http.get(global.API_URL + '/api/empresas/dashboard/' + id);
  }

  saveEmpresaConfiguration(conf: any, id: any) {
    return this.http.post( global.API_URL + '/api/empresas/saveconf/' + id, conf);
  }

  getCEO(id: any) {
    return this.http.get(global.API_URL + '/api/users/ceo/' + id);
  }
  
  editEmpresa(company: any, id: any) {
    company.audit_user = this.auth.currentUserValue.id;
    company.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/empresas/update/' + id, company);
  }

  deleteEmpresa(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/empresas/delete/' + id, data);
  }

  // DELEGACIONES

  getAllDelegationsByEmpresa(id: any) {
    return this.http.get(global.API_URL + '/api/delegations/' + id);
  }
  
  newDelegation(delegation: any) {
    delegation.audit_user = this.auth.currentUserValue.id;
    delegation.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/delegations/create', delegation);
  }
  getDelegation(id: any) {
    return this.http.get(global.API_URL + '/api/delegations/view/' + id);
  }
  updateDelegation(delegation: any, id: any) {
    delegation.audit_user = this.auth.currentUserValue.id;
    delegation.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/delegations/update/' + id, delegation);
  }
  deleteDelegation(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/delegations/delete/' + id, data);
  }

  // COMPAÑIAS

  getAllCompanies() {
    return this.http.get(global.API_URL + '/api/companies');
  }

  newCompany(company: any) {
    company.audit_user = this.auth.currentUserValue.id;
    company.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/companies/create', company);
  }

  getCompany(id: any) {
    return this.http.get(global.API_URL + '/api/companies/view/' + id);
  }
  
  editCompany(company: any, id: any) {
    company.audit_user = this.auth.currentUserValue.id;
    company.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/companies/update/' + id, company);
  }

  deleteCompany(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/companies/delete/' + id, data);
  }

  // TARIFAS MOVIL

  getAllMobilefees() {
    return this.http.get(global.API_URL + '/api/mobilefees');
  }
  geMobilefeesByCompany(company: any) {
    return this.http.get(global.API_URL + '/api/mobilefees/bycompany/' + company);
  }
  newMobilefee(fee: any) {
    return this.http.post( global.API_URL + '/api/mobilefees/create', fee);
  }
  getMobilefee(id: any) {
    return this.http.get(global.API_URL + '/api/mobilefees/view/' + id);
  }
  updateMobilefee(fee: any, id: any) {
    return this.http.post( global.API_URL + '/api/mobilefees/update/' + id, fee);
  }
  deleteMobilefee(id: any) {
    return this.http.delete(global.API_URL + '/api/mobilefees/delete/' + id);
  }

  // TARIFAS MOVIL

  getAllPacks() {
    return this.http.get(global.API_URL + '/api/packs');
  }
  getPacksByCompany(company: any) {
    return this.http.get(global.API_URL + '/api/packs/bycompany/' + company);
  }
  newPack(pack: any) {
    return this.http.post( global.API_URL + '/api/packs/create', pack);
  }
  getPack(id: any) {
    return this.http.get(global.API_URL + '/api/packs/view/' + id);
  }
  getTarifasPack(id: any){
    return this.http.get(global.API_URL + '/api/packs/get_tarifas/' + id);
  }
  updatePack(pack: any, id: any) {
    return this.http.post( global.API_URL + '/api/packs/update/' + id, pack);
  }
  deletePack(id: any) {
    return this.http.delete(global.API_URL + '/api/packs/delete/' + id);
  }

  // TARIFAS FIJO

  getAllFixedfees() {
    return this.http.get(global.API_URL + '/api/fixedfees');
  }
  getFixedfeesByCompany(company: any) {
    return this.http.get(global.API_URL + '/api/fixedfees/bycompany/' + company);
  }
  newFixedfee(fee: any) {
    return this.http.post( global.API_URL + '/api/fixedfees/create', fee);
  }
  getFixedfee(id: any) {
    return this.http.get(global.API_URL + '/api/fixedfees/view/' + id);
  }
  updateFixedfee(fee: any, id: any) {
    return this.http.post( global.API_URL + '/api/fixedfees/update/' + id, fee);
  }
  deleteFixedfee(id: any) {
    return this.http.delete(global.API_URL + '/api/fixedfees/delete/' + id);
  }

  //DOCUMENTATION 

  getDocTypeByCompany(company: any) {
    return this.http.get(global.API_URL + '/api/documentstypes/bycompany/'+company);
  }
  newDocType(type: any) {
    return this.http.post( global.API_URL + '/api/documentstypes/create', type);
  }
  getDocType(id: any) {
    return this.http.get(global.API_URL + '/api/documentstypes/view/' + id);
  }
  updateDocType(type: any, id: any) {
    return this.http.post( global.API_URL + '/api/documentstypes/update/' + id, type);
  }
  deleteDocType(id: any) {
    return this.http.delete(global.API_URL + '/api/documentstypes/delete/' + id);
  }

  //DOCUMENTATION PENALIZACIÓN

  getDocTypePenalizacionByCompany(company: any) {
    return this.http.get(global.API_URL + '/api/tipodocpenalizacion/bycompany/'+company);
  }
  newDocTypePenalizacion(type: any) {
    return this.http.post( global.API_URL + '/api/tipodocpenalizacion/create', type);
  }
  getDocTypePenalizacion(id: any) {
    return this.http.get(global.API_URL + '/api/tipodocpenalizacion/view/' + id);
  }
  updateDocTypePenalizacion(type: any, id: any) {
    return this.http.post( global.API_URL + '/api/tipodocpenalizacion/update/' + id, type);
  }
  deleteDocTypePenalizacion(id: any) {
    return this.http.delete(global.API_URL + '/api/tipodocpenalizacion/delete/' + id);
  }

  //TERMINALES

  getAllTerminals() {
    return this.http.get(global.API_URL + '/api/terminals');
  }
  
  getTerminalsCompany(company: any) {
    return this.http.get(global.API_URL + '/api/terminals/bycompany/'+company);
  }
  newTerminal(term: any) {
    return this.http.post( global.API_URL + '/api/terminals/create', term);
  }
  getTerminal(id: any) {
    return this.http.get(global.API_URL + '/api/terminals/view/' + id);
  }
  updateTerminal(term: any, id: any) {
    return this.http.post( global.API_URL + '/api/terminals/update/' + id, term);
  }
  deleteTerminal(id: any) {
    return this.http.delete(global.API_URL + '/api/terminals/delete/' + id);
  }

  //ESTADOS CONTRATO

  getAllEstadoscontrato() {
    return this.http.get(global.API_URL + '/api/estadoscontrato');
  }
  
  getEstadoscontratoCompany(company: any) {
    return this.http.get(global.API_URL + '/api/estadoscontrato/bycompany/'+company);
  }
  newEstadocontrato(term: any) {
    return this.http.post( global.API_URL + '/api/estadoscontrato/create', term);
  }
  getEstadocontrato(id: any) {
    return this.http.get(global.API_URL + '/api/estadoscontrato/view/' + id);
  }
  updateEstadocontrato(term: any, id: any) {
    return this.http.post( global.API_URL + '/api/estadoscontrato/update/' + id, term);
  }
  deleteEstadocontrato(id: any) {
    return this.http.delete(global.API_URL + '/api/estadoscontrato/delete/' + id);
  }


  //ESTADOS INSTALACION

  getAllEstadosinstalacion() {
    return this.http.get(global.API_URL + '/api/estadosinstalacion');
  }
  
  getEstadosinstalacionCompany(company: any) {
    return this.http.get(global.API_URL + '/api/estadosinstalacion/bycompany/'+company);
  }
  newEstadoinstalacion(term: any) {
    return this.http.post( global.API_URL + '/api/estadosinstalacion/create', term);
  }
  getEstadoinstalacion(id: any) {
    return this.http.get(global.API_URL + '/api/estadosinstalacion/view/' + id);
  }
  updateEstadoinstalacion(term: any, id: any) {
    return this.http.post( global.API_URL + '/api/estadosinstalacion/update/' + id, term);
  }
  deleteEstadoinstalacion(id: any) {
    return this.http.delete(global.API_URL + '/api/estadosinstalacion/delete/' + id);
  }

  //SERVICIOS ADICIONALES

  getAllServiciosadicionales() {
    return this.http.get(global.API_URL + '/api/serviciosadicionales');
  }
  
  getServiciosadicionalesCompany(company: any) {
    return this.http.get(global.API_URL + '/api/serviciosadicionales/bycompany/'+company);
  }
  newServicioadicional(term: any) {
    return this.http.post( global.API_URL + '/api/serviciosadicionales/create', term);
  }
  getServicioadicional(id: any) {
    return this.http.get(global.API_URL + '/api/serviciosadicionales/view/' + id);
  }
  updateServicioadicional(term: any, id: any) {
    return this.http.post( global.API_URL + '/api/serviciosadicionales/update/' + id, term);
  }
  deleteServicioadicional(id: any) {
    return this.http.delete(global.API_URL + '/api/serviciosadicionales/delete/' + id);
  }

  //TARIFAS TERMINALES

  getAllTerminalsfees(id:any) {
    return this.http.get(global.API_URL + '/api/terminalsfees/' + id);
  }
  newTerminalfee(term:any) {
    return this.http.post( global.API_URL + '/api/terminalsfees/create', term);
  }
  getTerminalfee(id:any) {
    return this.http.get(global.API_URL + '/api/terminalsfees/view/' + id);
  }
  updateTerminalfee(term:any, id:any) {
    return this.http.post( global.API_URL + '/api/terminalsfees/update/' + id, term);
  }
  deleteTerminalfee(id:any) {
    return this.http.delete(global.API_URL + '/api/terminalsfees/delete/' + id);
  }

  // PERMISOS

  getPermisos() {
    return this.http.get(global.API_URL + '/api/permisos');
  }
  newPermiso(permiso: any) {
    permiso.audit_user = this.auth.currentUserValue.id;
    permiso.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/permisos/create', permiso);
  }
  getPermiso(id: any) {
    return this.http.get(global.API_URL + '/api/permisos/view/' + id);
  }
  updatePermiso(permiso: any, id: any) {
    permiso.audit_user = this.auth.currentUserValue.id;
    permiso.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/permisos/update/' + id, permiso);
  }
  deletePermiso(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/permisos/delete/' + id, data);
  }

  // COMERCIALES
  getComercialesByCompany(empresa: any) {
    return this.http.get(global.API_URL + '/api/commercial/bycompany/' + empresa);
  }
  getComerciales() {
    return this.http.get(global.API_URL + '/api/commercial');
  }
  newComercial(comercial: any) {
    comercial.audit_user = this.auth.currentUserValue.id;
    comercial.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/commercial/create', comercial);
  }
  getComercial(id: any) {
    return this.http.get(global.API_URL + '/api/commercial/view/' + id);
  }
  updateComercial(comercial: any, id: any) {
    comercial.audit_user = this.auth.currentUserValue.id;
    comercial.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/commercial/update/' + id, comercial);
  }
  deleteComercial(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/commercial/delete/' + id, data);
  }

  // BACKOFFICES
  getBackofficesByCompany(empresa: any) {
    return this.http.get(global.API_URL + '/api/backoffices/bycompany/' + empresa);
  }
  getBackoffices() {
    return this.http.get(global.API_URL + '/api/backoffices');
  }
  newBackoffice(backoffice: any) {
    backoffice.audit_user = this.auth.currentUserValue.id;
    backoffice.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/backoffices/create', backoffice);
  }
  getBackoffice(id: any) {
    return this.http.get(global.API_URL + '/api/backoffices/view/' + id);
  }
  updateBackoffice(backoffice: any, id: any) {
    backoffice.audit_user = this.auth.currentUserValue.id;
    backoffice.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/backoffices/update/' + id, backoffice);
  }
  deleteBackoffice(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/backoffices/delete/' + id, data);
  }


  // ESTADOS LEADS

  getAllEstadosleadsByEmpresa(id: any) {
    return this.http.get(global.API_URL + '/api/estadosleads/' + id);
  }
  newEstadolead(estadolead: any) {
    estadolead.audit_user = this.auth.currentUserValue.id;
    estadolead.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/estadosleads/create', estadolead);
  }
  getEstadolead(id: any) {
    return this.http.get(global.API_URL + '/api/estadosleads/view/' + id);
  }
  updateEstadolead(estadolead: any, id: any) {
    estadolead.audit_user = this.auth.currentUserValue.id;
    estadolead.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/estadosleads/update/' + id, estadolead);
  }
  deleteEstadolead(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/estadosleads/delete/' + id, data);
  }

   // UBICACIONES
  getAllComunidades() {
    return this.http.get(global.API_URL + '/api/comunidades');
  }
  getProvinciaByComunidad(id: string) {
      return this.http.get(global.API_URL + '/api/provinces/comunidad/' + id);
  }
  getCiudadesByProvincia(id: string){
        return this.http.get(global.API_URL + '/api/cities/byprovince/' + id);
  }

  // CAMPAÑAS

  getAllCampaignsByEmpresa(id:any) {
    return this.http.get(global.API_URL + '/api/campaings/bycompany/' + id);
  }
  newCampaign(camp:any) {
    camp.audit_user = this.auth.currentUserValue.id;
    camp.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/campaings/create', camp);
  }
  getCampaign(id: any) {
    return this.http.get(global.API_URL + '/api/campaings/view/' + id);
  }
  updateCampaign(camp: any, id: any) {
    camp.audit_user = this.auth.currentUserValue.id;
    camp.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/campaings/update/' + id, camp);
  }
  deleteCampaign(id:any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/campaings/delete/' + id, data);
  }

  // COMERCIALES
  getEquiposByCompany(empresa: any) {
    return this.http.get(global.API_URL + '/api/equipos/bycompany/' + empresa);
  }
  getEquipos() {
    return this.http.get(global.API_URL + '/api/equipos');
  }
  newEquipo(equipo: any) {
    equipo.audit_user = this.auth.currentUserValue.id;
    equipo.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/equipos/create', equipo);
  }
  getEquipo(id: any) {
    return this.http.get(global.API_URL + '/api/equipos/view/' + id);
  }
  updateEquipo(equipo: any, id: any) {
    equipo.audit_user = this.auth.currentUserValue.id;
    equipo.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/equipos/update/' + id, equipo);
  }
  deleteEquipo(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/equipos/delete/' + id, data);
  }
  assignSupervisoresEquipo(data: any, id: any){
    return this.http.post(global.API_URL + '/api/equipos/assign_supervisores/' + id, data);
  }
  assignJefesEquipo(data: any, id: any){
    return this.http.post(global.API_URL + '/api/equipos/assign_jefes/' + id, data);
  }
  assignComercialesEquipo(data: any, id: any){
    return this.http.post(global.API_URL + '/api/equipos/assign_comerciales/' + id, data);
  }
  reassignComercialesEquipo(data: any, id: any){
    return this.http.post(global.API_URL + '/api/equipos/reassign_comerciales/' + id, data);
  }
  getAllLeads() {
    return this.http.get(global.API_URL + '/api/leads');
  }

  getAllLeadsByCompany(id: any) {
    return this.http.get(global.API_URL + '/api/leads/bycompany/' + id);
  }

  getAllLeadsByCompanyFreeOwner(id: any) {
    return this.http.get(global.API_URL + '/api/leads/bycompany_freeowner/' + id);
  }

  newLead(lead: any) {
    lead.audit_user = this.auth.currentUserValue.id;
    lead.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leads/create', lead);
  }

  importarLeadsCSV(importacion: any) {
    return this.http.post( global.API_URL + '/api/leads/importar_csv', importacion);
  }
  
  getContractConfig(id:any) {
    return this.http.get(global.API_URL + '/api/contracts/config/' + id);
  }
  getLead(id: any) {
    return this.http.get(global.API_URL + '/api/leads/view/' + id);
  }

  updateLead(lead: any, id: any) {
    lead.audit_user = this.auth.currentUserValue.id;
    lead.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leads/update/' + id, lead);
  }

  deleteLead(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/leads/delete/' + id, data);
  }

  assignOwnersLead(data: any, id: any){
    return this.http.post(global.API_URL + '/api/leads/assign/' + id, data);
  }
  
  assignOwnersBulk(data: any, id: any){
    return this.http.post(global.API_URL + '/api/leads/assign_bulk/' + id, data);
  }
  // LEAD NOTAS

  getLeadnotasByLead(lead: any) {
    return this.http.get(global.API_URL + '/api/leadsnotas/bylead/' + lead);
  }
  getLeadsnotas() {
    return this.http.get(global.API_URL + '/api/leadsnotas');
  }
  newLeadnota(nota: any, lead: any) {
    nota.audit_user = this.auth.currentUserValue.id;
    nota.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsnotas/create/'+lead, nota);
  }
  getLeadnota(id: any) {
    return this.http.get(global.API_URL + '/api/leadsnotas/view/' + id);
  }
  updateLeadnota(nota: any, id: any) {
    nota.audit_user = this.auth.currentUserValue.id;
    nota.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsnotas/update/' + id, nota);
  }
  deleteLeadnota(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/leadsnotas/delete/' + id, data);
  }

  // LEAD VISITAS

  getLeadvisitasByLead(lead: any) {
    return this.http.get(global.API_URL + '/api/leadsvisitas/bylead/' + lead);
  }
  getLeadsvisitas() {
    return this.http.get(global.API_URL + '/api/leadsvisitas');
  }
  newLeadvisita(visita: any, lead: any) {
    visita.audit_user = this.auth.currentUserValue.id;
    visita.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsvisitas/create/'+lead, visita);
  }
  getLeadvisita(id: any) {
    return this.http.get(global.API_URL + '/api/leadsvisitas/view/' + id);
  }
  finishLeadvisita(id: any) {
    return this.http.get(global.API_URL + '/api/leadsvisitas/finish/' + id);
  }
  updateLeadvisita(visita: any, id: any) {
    visita.audit_user = this.auth.currentUserValue.id;
    visita.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsvisitas/update/' + id, visita);
  }
  deleteLeadvisita(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/leadsvisitas/delete/' + id, data);
  }

  // LEAD DIRECCIONES

  getLeaddireccionesByLead(lead: any) {
    return this.http.get(global.API_URL + '/api/leadsdirecciones/bylead/' + lead);
  }
  getLeadsdirecciones() {
    return this.http.get(global.API_URL + '/api/leadsdirecciones');
  }
  newLeaddireccion(direccion: any, lead: any) {
    direccion.audit_user = this.auth.currentUserValue.id;
    direccion.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsdirecciones/create/'+lead, direccion);
  }
  getLeaddireccion(id: any) {
    return this.http.get(global.API_URL + '/api/leadsdirecciones/view/' + id);
  }
  updateLeaddireccion(direccion: any, id: any) {
    direccion.audit_user = this.auth.currentUserValue.id;
    direccion.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsdirecciones/update/' + id, direccion);
  }
  deleteLeaddireccion(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.
    http.post(global.API_URL + '/api/leadsdirecciones/delete/' + id, data);
  }

  // LEAD DOCUMENTOS  

  getLeaddocumentosByLead(lead: any) {
    return this.http.get(global.API_URL + '/api/leadsdocumentos/bylead/' + lead);
  }
  getLeadsdocumento() {
    return this.http.get(global.API_URL + '/api/leadsdocumentos');
  }
  newLeaddocumento(documento: any, lead: any) {
    documento.audit_user = this.auth.currentUserValue.id;
    documento.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsdocumentos/create/'+lead, documento);
  }
  getLeaddocumento(id: any) {
    return this.http.get(global.API_URL + '/api/leadsdirecciones/view/' + id);
  }
  updateLeaddocumento(documento: any, id: any) {
    documento.audit_user = this.auth.currentUserValue.id;
    documento.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post( global.API_URL + '/api/leadsdocumentos/update/' + id, documento);
  }
  deleteLeaddocumento(id: any) {
    const data: any = {};
    data.audit_user = this.auth.currentUserValue.id;
    data.audit_empresa = this.auth.currentUserValue.empresa_id;
    return this.http.post(global.API_URL + '/api/leadsdocumentos/delete/' + id, data);
  }

  getCities(id: any) {
    return this.http.get(global.API_URL + '/api/cities/byprovince/' + id);
  }
  getCustomer(id: any) {
    return this.http.get( global.API_URL + '/api/contracts/customer/' + id);
  }
  searchCustomer(data: any) {
    return this.http.post( global.API_URL + '/api/contracts/search', data);
  }
  newContract(contract: any) {
    return this.http.post( global.API_URL + '/api/contracts/create', contract);
  }

  updateContract(contract: any, id: any) {
    return this.http.post( global.API_URL + '/api/contracts/update/' + id, contract);
  }

  deleteContract(id: any) {
    return this.http.delete( global.API_URL + '/api/contracts/delete/' + id);
  }

  getContratosDashboard(id: any, mes: any, ano: any) {
    const data: any = {};
    data.mes = mes;
    data.ano = ano;
    return this.http.post(global.API_URL + '/api/contracts/dashboard_listado/' + id, data);
  }
  getContratosDashboardGrafica(id: any, mes: any, ano: any, filtro: any) {
    const data: any = {};
    data.mes = mes;
    data.ano = ano;
    data.filtro = filtro;
    return this.http.post(global.API_URL + '/api/contracts/dashboard_grafica/' + id, data);
  }
  getComercialesDashboard(id: any, mes: any, ano: any) {
    const data: any = {};
    data.mes = mes;
    data.ano = ano;
    return this.http.post(global.API_URL + '/api/contracts/dashboard_comerciales/' + id, data);
  }
  getDelegationsDashboard(id: any, mes: any, ano: any) {
    const data: any = {};
    data.mes = mes;
    data.ano = ano;
    return this.http.post(global.API_URL + '/api/delegations/dashboard/' + id, data);
  }
  getUltimosContratosEmpresa(empresa_id: any) {
    return this.http.get(global.API_URL + '/api/contracts/bycompany_latest/' + empresa_id);
  }

  getTerminalFeeData(term: any, fee:any) {
    return this.http.get(global.API_URL + '/api/terminalsfees/terminal/' + term + '/fee/' + fee);
  }

  getContract(id: any) {
    return this.http.get(global.API_URL + '/api/contracts/view/' + id);
  }

  getAllContractsByComercial(comercial_id: any) {
    return this.http.get(global.API_URL + '/api/contracts/bycommercial/' + comercial_id);
  }

  getAllContractsByCompany(empresa_id: any) {
    return this.http.get(global.API_URL + '/api/contracts/bycompany/' + empresa_id);
  }

  sendSignEmail(id: any, data:any){
    return this.http.post( global.API_URL + '/api/contracts/sign_mail/' + id, data);
  }
  sign_process(id: any, key: any) {
    const data: any = {};
    data.key = key;
    return this.http.post( global.API_URL + '/api/contracts/customer_sign/' + id, data);
  }

  sign_contract(id: any, data: any) {
    return this.http.post( global.API_URL + '/api/contracts/sign_contract/' + id, data);
  }

  generatePdfContracts(id: any) {
    return this.http.get(global.API_URL + '/api/contracts/generatepdf/' + id);
  }

  autosign_login(data: any) {
    return this.http.post( global.API_URL + '/api/contracts/autosign_login', data);
  }

  checkPin(data: any) {
    return this.http.post( global.API_URL + '/api/contracts/check_pin', data);
  }

  getAllContractsToCompany(doc_id: any) {
    return this.http.get(global.API_URL + '/api/contracts/tocustomer/' + doc_id);
  }

  getAllDocsByContract(contrato_id: any) {
    return this.http.get(global.API_URL + '/api/docs/bycontract/' + contrato_id);
  }
  getDoc(id: any) {
    return this.http.get(global.API_URL + '/api/docs/view/' + id);
  }

  newDoc(doc: any) {
    return this.http.post( global.API_URL + '/api/docs/create', doc);
  }

  updateDoc(doc: any, id: any) {
    return this.http.post( global.API_URL + '/api/docs/update/' + id, doc);
  }

  deleteDoc(id: any) {
    return this.http.delete(global.API_URL + '/api/docs/delete/' + id);
  }

}